













import { Component, Vue } from 'vue-property-decorator'
import MainCard from '@/components/ui/MainCard.vue'
import ServerLogsTable from '@/components/admin/ServerLogsTable.vue'

@Component({ components: { MainCard, ServerLogsTable } })
export default class ServerLogs extends Vue {}
