import moment from 'moment'
import _ from 'lodash'
import dateFormat from '@/config/default/dateFormat'
import { LogInfoEntry, LogErrorEntry } from '@/types/interfaces/logs'

export class LogParser {
  public static parseLogFile(
    log: string,
    type: 'info' | 'error'
  ): (LogInfoEntry | LogErrorEntry)[] {
    const lines = log.split('\n')
    const result = []
    if (type === 'info') {
      for (const line of lines) {
        if (line.length > 0) {
          const parsed = this.parseInfoLine(line)
          if (parsed) {
            result.push(parsed)
          }
        }
      }
    } else if (type === 'error') {
      for (const line of lines) {
        if (line.length > 0) {
          result.push(this.parseErrorLine(line))
        }
      }
    }
    _.sortBy(result, ['timestamps'])
    return result
  }

  private static parseInfoLine(line: string): LogInfoEntry | null {
    const elems = line.split(',')
    // elems.length < 11. На сервере лог в формате csv пишется в 12 столбцов. Если меньше, то в логе запись не про запрос к API
    if (elems.length < 11) {
      return null
    }
    const [method, url] = elems[6].slice(1, -1).split(' ')
    return {
      timestamp: elems[0].slice(1, -1),
      level: elems[1].slice(1, -1),
      remoteAddr: elems[2].slice(1, -1),
      realIp: elems[3].slice(1, -1),
      remoteUser: elems[4].slice(1, -1),
      authUser: elems[5].slice(1, -1),
      method,
      url,
      status: Number(elems[7].slice(1, -1)),
      responseTime: Number(elems[8].slice(1, -4)),
      contentLength: Number(elems[9].slice(1, -1)),
      referrer: elems[10].slice(1, -1),
      userAgent: elems[11].slice(1, -1)
    }
  }

  private static parseErrorLine(line: string): LogErrorEntry {
    const obj = JSON.parse(line)
    obj.timestamp = moment(obj.timestamp).format(dateFormat.date.inputFormat)
    return obj
  }
}
