





































import MultiSelect from 'vue-multiselect'
import { AgGridVue } from 'ag-grid-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  GridOptions,
  GridApi,
  ColDef,
  CellClickedEvent
} from 'ag-grid-community'
import { BModal } from 'bootstrap-vue'

import AgGridFactory from '@/factories/agGridFactory'
import LoggerAPI from '@/api/requests/logger'
import SetFilter from '@/components/table/filters/SetFilter'
import ruMultiselect from '@/config/translation/multiselect'
import { LogParser } from '@/api/adapters/logs'
import { LogInfoEntry, LogErrorEntry } from '@/types/interfaces/logs'

@Component({ components: { AgGridVue, SetFilter, MultiSelect } })
export default class ServerLogsTable extends Vue {
  @Prop(String) private readonly type!: 'info' | 'error'

  private selectedFile: string | null = null
  private fileList: string[] = []
  private ruMultiselect = ruMultiselect

  private stacktrace = ''
  private queryParams = ''

  $refs!: { stackModal: BModal }

  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    paginationPageSize: 100,
    onGridReady: this.onGridReady,
    onCellClicked: this.onCellClicked,
    frameworkComponents: { SetFilter }
  }

  private get columnDefs(): ColDef[] {
    if (this.type === 'info') {
      return [
        {
          field: 'timestamp',
          headerName: 'Timestamp'
        },
        {
          field: 'realIp',
          headerName: 'Real IP'
        },
        {
          field: 'authUser',
          headerName: 'User'
        },
        {
          field: 'method',
          headerName: 'Method',
          width: 100,
          filterFramework: 'SetFilter',
          filterParams: {
            keys: ['GET', 'POST', 'PUT', 'DELETE']
          }
        },
        {
          field: 'url',
          headerName: 'URL',
          width: 400
        },
        {
          field: 'status',
          headerName: 'Status',
          filter: 'agNumberColumnFilter',
          width: 100
        },
        {
          field: 'responseTime',
          headerName: 'Time (ms)',
          filter: 'agNumberColumnFilter',
          width: 100
        },
        {
          field: 'contentLength',
          headerName: 'Size (b)',
          filter: 'agNumberColumnFilter',
          width: 100
        },
        {
          field: 'referrer',
          headerName: 'Referrer'
        },
        {
          field: 'userAgent',
          headerName: 'UserAgent'
        }
      ]
    } else {
      return [
        {
          field: 'timestamp',
          headerName: 'Timestamp'
        },
        {
          field: 'user',
          headerName: 'User'
        },
        {
          field: 'level',
          headerName: 'Level',
          width: 100
        },
        {
          field: 'method',
          headerName: 'Method',
          width: 100,
          filterFramework: 'SetFilter',
          filterParams: {
            keys: ['GET', 'POST', 'PUT', 'DELETE']
          }
        },
        {
          field: 'path',
          headerName: 'URL',
          width: 400
        },
        {
          field: 'message',
          headerName: 'Message',
          width: 400
        }
      ]
    }
  }

  private gridApi?: GridApi
  private rowData: (LogInfoEntry | LogErrorEntry)[] = []

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
    this.loadData()
  }

  private get fileName() {
    if (!this.selectedFile) {
      return 0
    } else {
      return this.fileList.indexOf(this.selectedFile)
    }
  }

  private async loadData() {
    await this.$nextTick()
    const response = await LoggerAPI.getApiLogs(this.fileName, this.type)
    this.fileList = response.data.logFileNames
    if (!this.selectedFile) {
      this.selectedFile = this.fileList[0]
    }
    this.rowData = LogParser.parseLogFile(response.data.logs, this.type)
    this.gridApi!.setRowData(this.rowData)
  }

  private onCellClicked(params: CellClickedEvent) {
    if (params.data.stacktrace) {
      this.stacktrace = params.data.stacktrace
      if (params.data.query) {
        this.queryParams = params.data.query
      } else {
        this.queryParams = ''
      }
      this.$refs.stackModal.show()
    }
  }
}
